import * as React from 'react';
import { graphql, Link } from 'gatsby';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Art = ({ data }) => {
  return (
    <Layout>
      <Seo title="Art Diario - Previous Art" />
      <h1 className="text-2xl sm:text-4xl text-center leading-10 text-slate-400 font-extrabold mb-5 sm:mb-20 max-w-2xl mx-auto">
        Previous Art
      </h1>
      <ul className="space-y-5 sm:-mt-4 max-w-4xl mx-auto">
        {data.allMdx.nodes.map((node) => (
          <li>
            <Link to={`/${node.slug}`}>
              <div>
                {node.frontmatter.title.replace(' - High Resolution', '')}
              </div>
              <div className="text-slate-400">{node.frontmatter.date}</div>
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: "art" } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
  }
`;

export default Art;
